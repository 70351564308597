
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    containerSize: {
      type: [Number, String],
      default: 6,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    environmentId: {
      type: String,
      default: null,
    },
    titleHidden: {
      type: Boolean,
      default: false,
    },
    intro: {
      type: String,
      default: null,
    },
    featuredImage: {
      type: String,
      default: null,
    },
    featuredVideo: {
      type: Array,
      default: null,
    },
    content: {
      type: Array,
      default: null,
    },
    seo: {
      type: Object,
      default: null,
    },
    social: {
      type: Object,
      default: null,
    },
    stories: {
      type: Array,
      default: null,
    },
    preprOverwrite: {
      type: Object,
      default: null,
    },
    cookiePermissions: {
      type: Array,
      default: null,
    },
  },
}
